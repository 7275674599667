import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { AppProps } from 'next/app'
import superjson from 'superjson'

import { ObjectId } from '@elendi/util-objectid'

superjson.registerCustom<ObjectId, string>(
  {
    isApplicable: (v): v is ObjectId => v instanceof ObjectId,
    serialize: (v) => v.toHexString(),
    deserialize: (v) => new ObjectId(v),
  },
  'objectid',
)

const SUPERJSON_KEY_NAME = '$superjson'

export const serialKill = (pageProps: AppProps['pageProps']): AppProps['pageProps'] => {
  // @ts-ignore
  if (SUPERJSON_KEY_NAME in pageProps) return superjson.parse(pageProps[SUPERJSON_KEY_NAME])
  return pageProps
}

export const withSerialKiller =
  <T extends { [key: string]: unknown }>(
    gssp: GetServerSideProps<T>,
  ): GetServerSideProps<{ [SUPERJSON_KEY_NAME]: string }> =>
  async (gsspArgs: GetServerSidePropsContext) => {
    const gsspResult = await gssp(gsspArgs)
    if ('props' in gsspResult) {
      return {
        props: { [SUPERJSON_KEY_NAME]: superjson.stringify(gsspResult.props) },
      }
    }
    return gsspResult
  }
